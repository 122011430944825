import Cookies from 'js-cookie';
import { clearData } from '@bsd/ui-auth';
import { APP_NAMES, UN_AUTH_TRACKING_ID } from './constants';
import { getAppName, getMockPathName } from './script';
import {
  matchGenericRules,
  matchFlags,
  matchRole,
  matchServices,
  matchUserServices,
  matchDisplayFlags,
} from './displayRules';
import { getConfig } from 'config';

export const getContentConfig = () => getConfig().contentConfig;

export const getNavSaConfig = () => getConfig().navSaConfig;

export const getIsProd = () => getConfig().isProductionEnvironment;

export const getCurrentPath = () => {
  const mockPathName = getMockPathName();
  return !getIsProd() && mockPathName
    ? mockPathName
    : window.location.pathname + window.location.search + window.location.hash;
};

export const getTracerName = (requestName) => {
  const callingComponent = getAppName() || 'unknownApplication';
  const { applicationId } = getConfig();

  return `${callingComponent} --> ${applicationId} --> ${requestName}`;
};

/**
 * Function used to determine if footer modifier is enterprise or smallBusiness
 * @returns {string}
 */
export const getFooterModifier = () => {
  if (getCurrentPath().startsWith('/enterprise')) {
    return 'enterprise';
  }

  return 'smallBusiness';
};

/**
 * Function used to determine if we are on a CBM page
 * @returns {boolean}
 */
export const getIsCBMPage = () =>
  [APP_NAMES.MobileBuy, APP_NAMES.MobileLearn].includes(getAppName());

export const getLogoUrl = (appName, isAuthenticated, logoLinks, scriptData) => {
  if (typeof scriptData?.homepageOverride === 'string') {
    return scriptData.homepageOverride;
  }
  const logoLink = logoLinks[appName];

  if (logoLink) return logoLink;

  return isAuthenticated
    ? logoLinks['DefaultAuthenticated']
    : logoLinks[APP_NAMES.BSEE];
};

export const getSearchUrl = (appName, searchUrls, featureFlags = {}) => {
  const urls = matchFlags(searchUrls, featureFlags)
    ? { ...searchUrls, ...searchUrls.alternateUrls }
    : searchUrls;

  return appName === APP_NAMES.BSEE ? urls.shop : urls.nonShop;
};

/**
 * Finds current account in the list of available accounts
 * @param {array} accounts - collection of accounts
 * @param {string} selectedId - authGuid or orderId of current account
 * @returns current account object
 */
export const getCurrentAccount = (accounts, selectedId) => {
  if (!accounts?.length || !selectedId) {
    return {};
  }

  return accounts.find(
    (account) =>
      account.authGuid === selectedId || account.orderId === selectedId
  );
};

/**
 * Function used to retrieve phone data from content
 * @param isAuthenticated
 * @param isOrion
 * @param l1RightData
 * @returns {{phoneNumber: string}|*}
 */
export const getPhoneData = (isAuthenticated, isOrion, l1RightData) => {
  if (isOrion) return null;

  const {
    authenticated: [authPhoneData],
    unauthenticated: [defaultPhoneData],
    enterprise: [enterprisePhoneData],
    comcastmobile: [comcastMobilePhoneData],
  } = l1RightData;

  const isEnterprise = getCurrentPath().startsWith('/enterprise');

  if (isEnterprise) return enterprisePhoneData;
  if (getAppName() === APP_NAMES.BSEE) {
    return isAuthenticated ? authPhoneData : defaultPhoneData;
  }
  if ([APP_NAMES.MobileBuy, APP_NAMES.MobileLearn].includes(getAppName())) {
    return comcastMobilePhoneData;
  }

  return null;
};

/**
 * builds the list of items to be displayed in the ProfileMenu Dropdown
 * @param profileMenuItems - profile object array for menu items from content
 * @param selectedAccount - currently selected account
 * @param alternateSignOutUrl - alternate url for Signing Out
 * @param featureFlags - object containing current feature flags
 * @returns {*[]} - profileItems array ready to be displayed
 */
export const buildProfileItems = (
  profileMenuItems,
  selectedAccount,
  alternateSignOutUrl,
  featureFlags
) => {
  if (!profileMenuItems) return [];

  return profileMenuItems.reduce((res, item) => {
    if (
      !matchGenericRules(item, selectedAccount, ['isOrion']) ||
      !matchServices(item, selectedAccount)
    ) {
      return res;
    }

    const updatedItem = { ...item };

    if (item.DisplayText === 'Sign Out') {
      updatedItem.DisplayLink = alternateSignOutUrl || item.DisplayLink;
      updatedItem.onClick = () => {
        clearData();
      };
    }

    if (!matchFlags(item, featureFlags)) {
      return res;
    }

    res.push(updatedItem);

    return res;
  }, []);
};

/**
 * Updates L1 right items comcast mobile links by display conditions
 * @param {array} l1Items - array of L1 items
 * @param {object} featureFlags - flags from content to check for valid feature flag - display condition
 * @returns Updated L1 items
 */
export const getCustomizedL1RightItems = (l1Items, featureFlags) => {
  const filteredComcastMobileLinks = l1Items.comcastmobile.filter((item) => {
    const objWithDisplayCondition = Object.values(item).find(
      (itemValue) => itemValue.DisplayCondition
    );

    if (!objWithDisplayCondition) {
      return true;
    }
    const values = { isCBMPageOnly: getIsCBMPage() };
    const displayFlags = ['isCBMPageOnly'];
    return (
      matchFlags(objWithDisplayCondition, featureFlags) &&
      matchDisplayFlags(objWithDisplayCondition, displayFlags, values)
    );
  });

  return {
    ...l1Items,
    comcastmobile: filteredComcastMobileLinks,
  };
};

/**
 * Updates L1 items with Support children filtered by available services in account
 * or by availability for unauthenticated state
 * @param {array} l1Items - array of L1 items
 * @param {object} selectedAccount - currently selected account. if not provided - we are in unauthenticated state
 * @param {array} initialSupportChildren - initial array of Support menu children. if not provided - we use support children from first argument
 * @param {object} featureFlags - flags from content to check for valid feature flag - display condition
 * @returns Updated L1 items
 */
export const customizeL1Items = (
  l1Items,
  selectedAccount,
  initialSupportChildren,
  featureFlags
) => {
  const updatedL1Items = l1Items.reduce((res, item) => {
    if (item.DisplayText !== 'Support') {
      res.push(item);
      return res;
    }

    // TEMPORARY FUNCTIONALITY - to support new H&S url behind flag
    if (matchFlags(item, featureFlags)) {
      if (item.AlternateLinks?.length === 1) {
        item.DisplayLink = item.AlternateLinks[0];
      }
    }
    // END TEMPORARY

    const supportChildren = initialSupportChildren || item.children || [];

    const filteredSupport = {
      ...item,
      children: selectedAccount
        ? supportChildren.reduce((result, child) => {
            // do not include items that don't match current account for authenticated state
            if (
              !matchGenericRules(child, selectedAccount, [
                'isPreAccount',
                'isPreInstall',
                'isOrion',
                'isSingleView',
                'isClosed',
              ]) ||
              !matchFlags(child, featureFlags) ||
              !matchRole(child, selectedAccount) ||
              !matchServices(child, selectedAccount) ||
              !matchUserServices(child, selectedAccount)
            ) {
              return result;
            }

            if (child?.DisplayCondition?.appendAuthGuid) {
              addDynamicAuthGuid(child, selectedAccount);
            }

            const currentSupportChild = item.children.find(
              (currentChild) =>
                currentChild.DisplayLink === child.DisplayLink &&
                currentChild.DisplayText === child.DisplayText
            );
            const isCurrentlySelected =
              currentSupportChild?.DisplayCondition?.isSelected;

            if (isCurrentlySelected) {
              const updatedChild = JSON.parse(JSON.stringify(child));
              updatedChild.DisplayCondition.isSelected = true;
              result.push(updatedChild);
            } else {
              result.push(child);
            }

            return result;
          }, [])
        : [],
    };

    res.push(filteredSupport);
    return res;
  }, []);

  return updatedL1Items;
};

/**
 * Set the item to local storage with expiration time
 * @param {string} key - key we are setting to local storage
 * @param {string | boolean} value - value for the key
 * @param {Object} options - Options to help manage the browser storage item
 * @param {number} options.timeToLive - "time to live" for the stored item in milliseconds
 * @param {string} [options.trackingId] - "tracking Id" to store.
 * @param {boolean} [options.isLocalStorage] - determines which browser storage to use
 */
export const setBrowserStorageWithExpiry = (
  key,
  value,
  { timeToLive, trackingId, isLocalStorage = true }
) => {
  const storage = isLocalStorage ? localStorage : sessionStorage;
  const now = new Date();
  const item = {
    ...(trackingId && { trackingId }),
    value,
    expiry: now.getTime() + timeToLive,
  };

  storage.setItem(key, JSON.stringify(item));
};

/**
 * Get the value from local or session storage. Removes local or session storage item if it is
 * expired or if the tracking id does not match.
 * @param {string} key - key we are retrieving from browser storage.
 * @param {string} [trackingId] - "tracking Id" to store.
 * @returns value for local or session storage item or null if the item does not exist or expired.
 */
export const getBrowserStorageWithExpiry = (key, trackingId) => {
  const itemStr = localStorage.getItem(key) || sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (trackingId && trackingId !== item.trackingId) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
    return null;
  }

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
    return null;
  }

  return item.value;
};

/**
 *  Get the value of tracking id from cookie .
 *  * @returns Tracking Id value.
 */
export const getTrackingId = () => {
  const trackId = Cookies.get('cb_ucid');
  return trackId ? trackId : UN_AUTH_TRACKING_ID;
};

/**
 *  Get value of tracking ID from the getTrackignId function .
 *  * @returns Tracking Id Header.
 */
export const getTrackingIdHeader = () => {
  const ucid = getTrackingId();
  return ucid !== UN_AUTH_TRACKING_ID
    ? {
        'tracking-id': ucid,
      }
    : {};
};

/**
 *
 * @param {string} string String with tokens
 * @param {array} args An array containing data which replaces tokens in order
 * @returns A formatted string with replaced tokens
 * @example
 *
 * const string = "Click {0} or {1} for more details".
 * const data = ["link1", "link2"]
 *
 * const formattedString = replaceTokensWithContent(string, data);
 * // => 'Click link1 or link2 for more details.'
 */
export function replaceTokensWithContent(string, ...args) {
  if (!args || !args.length) {
    return string;
  }

  return string
    .replace(/\{(\d)\}/g, (token) => {
      const index = token[1];

      return args[index];
    })
    .replace(/,/g, ',');
}

export const addDynamicAuthGuid = (navItem, accountData) => {
  if (navItem?.DynamicLink) {
    navItem.DisplayLink = replaceTokensWithContent(
      navItem.DynamicLink,
      accountData?.authGuid
    );
  }
};

/**
 *
 * @returns {string | null} - The visitor session id from the SC cookie
 * @description
 *
 *  The SC cookie has the following format below
 *  'RC.USID=b21e1611-8589-4405-9577-ce43029f45a7&RC.IFL=N&GEO=True'
 *  this function returns the RC.USID
 */
export const getVisitorSessionId = () => {
  const scCookieValue = Cookies.get('SC');
  const scCookieValueParams = new URLSearchParams(scCookieValue);
  return scCookieValueParams.get('RC.USID');
};
